.App {
  text-align: center;
}
body{
  background: white;
}
.main{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.fleximg{
  display: flex;
}
.fleximg img{
  height: 25vh;
  width: 25vh;
}
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;500&display=swap');
h1, p{
  text-align: center;
  width: 80%;
  margin: auto;
  font-family: Raleway;
  font-weight: 500;
}