@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@300;500&display=swap);
.App {
  text-align: center;
}
body{
  background: white;
}
.main{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.fleximg{
  display: flex;
}
.fleximg img{
  height: 25vh;
  width: 25vh;
}
h1, p{
  text-align: center;
  width: 80%;
  margin: auto;
  font-family: Raleway;
  font-weight: 500;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

